import React from 'react'
import s from './PoliticalPage.module.sass'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
function PoliticalPage() {
  const navigate = useNavigate()
  return (
    <div className={s.container}>
        <Helmet>
          <title>Политика конфиденциальности</title>
          <meta name="description" content="Политика конфиденциальности сайта Gelapart" />
        </Helmet>
        <div className={s.innerContainer}>
            <div className={s.path} style={{cursor: "pointer"}} onClick={() => navigate('/')}>Главная</div>
                <div className={s.title}>
                    <p className={s.slash}>/</p>
                    <p className={s.titlePath}>Политика конфиденциальности</p>
                </div>

                <div className={s.title1}>1. Основные понятия</div>
                <p>Пользователь – физическое или юридическое лицо, разместившее свою персональную информацию посредством Формы обратной связи на сайте с последующей целью передачи данных Администрации Сайта. Форма обратной связи – специальная форма, где Пользователь размещает свою персональную информацию с целью передачи данных Администрации Сайта и Приложения.</p>
                <div className={s.title1}>2. Общие положения</div>
                <p>2.1. Настоящая Политика конфиденциальности является официальным типовым документом Администрации Сайта и определяет порядок обработки и защиты информации о физических и юридических лицах, использующих Форму обратной связи на Сайте и в Приложении .<br></br>
2.2. Целью настоящей Политики конфиденциальности является обеспечение надлежащей защиты информации о Пользователе, в т.ч. его персональных данных от несанкционированного доступа и разглашения.<br></br>
2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях регулируются настоящей Политикой конфиденциальности и действующим законодательством Российской Федерации.<br></br>
2.4. Действующая редакция Политики конфиденциальности, является публичным документом, разработана Администрацией Сайта и доступна любому Пользователю сети Интернет при переходе по гипертекстовой ссылке «Политика конфиденциальности».<br></br>
2.5. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности.<br></br>
2.6. При внесении изменений в Политику конфиденциальности, Администрация Сайта уведомляет об этом Пользователя путём размещения новой редакции Политики конфиденциальности<br></br>
2.7. При размещении новой редакции Политики конфиденциальности на Сайте, предыдущая редакция хранятся в архиве документации Администрации Сайта.</p>
        </div>

    </div>
  )
}

export default PoliticalPage